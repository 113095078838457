@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');

body {
  margin: 0px;
  font-family: 'Open Sans';
  font-weight: 400;
  letter-spacing: 3px;
  position: relative;
  text-align: center;
  min-height: 90vh;
}
section {
  position: relative;
  text-align: center;
}
h1 {
  color: white;
  padding-top: 15px;
  font-size: 35px;
  margin: 0;
}
textarea {
  width: 350px;
  min-height: 100px;
  border-radius: 10px;
}
button {
  padding: 10px 30px;
  border-radius: 10px;
  border: none;
  background: linear-gradient(10deg, #bbbbbb, transparent);
  cursor: pointer;
  font-size: 20px;
  transition: all 0.6s ease;
  font-family: 'Open Sans';
  font-weight: 400;
  box-shadow: 0px 5px 15px #27273c;
  text-shadow: 0px 0px 10px #ffffff;
}

.boxes {
  margin: auto;
}
.box {
  margin: 7px;
  display: inline-block;
}
.forprint {
  display: none;
}
.forprint h1 {
  color: black;
}
.header-line {
  width: 100%;
}
.padding-div {
  height: 70px;
  width: 100%;
}
.footer {
  border-top: 1px solid #000000;
  position: absolute;
  bottom: -60px;
  text-align: center;
  background-color: #fff;
  height: 50px;
  width: 100%;
}
.forprint h1 {
  padding-top: 0px;
  margin: 5px;
}
.forprint h3 {
  margin: 5px;
}
.box {
  page-break-inside: avoid;
}
.print-doc {
  padding-bottom: 70px;
}

@media print {
  .forprint {
    display: block !important;
  }
  form {
    display: none;
  }
  .array-out {
    display: none;
  }
  .print-doc {
    display: none;
  }
  .notforprint {
    display: none;
  }
}
