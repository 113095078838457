.winter {
  .waveWrapperInner {
    background-image: linear-gradient(to top, #37d5d6 20%, #36096d 80%);
  }

  .season-button {
    &:hover {
      background-color: #36096d;
    }
  }
}
.spring {
  .waveWrapperInner {
    background-image: linear-gradient(to top, #eec0c6 20%, #ffa9a9 80%);
  }

  .season-button {
    &:hover {
      background-color: #ffa9a9;
    }
  }
}
.summer {
  .waveWrapperInner {
    background-image: linear-gradient(to top, #20bf55 20%, #0f4e12 80%);
  }

  .season-button {
    &:hover {
      background-color: #0f4e12;
    }
  }
}
.autumn {
  .waveWrapperInner {
    background-image: linear-gradient(to top, #f5d020 20%, #ff8461 80%);
  }
  .season-button {
    &:hover {
      background-color: #ff8461;
    }
  }
}
