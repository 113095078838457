@keyframes move_wave {
  0% {
    transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

.waveWrapper {
  overflow: hidden;
  z-index: -10;
  position: absolute;
  left: 50%;
  width: 100%;
  margin-left: -50%;
  height: 100%;
  top: -1px;
}
.waveWrapperInner {
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  bottom: -1px;
}

.bgTop {
  z-index: 15;
  opacity: 0.5;
}
.bgMiddle {
  z-index: 10;
  opacity: 0.75;
}
.bgBottom {
  z-index: 5;
}
.wave {
  position: absolute;
  left: 0;
  width: 200%;
  height: 100%;
  background-repeat: repeat no-repeat;
  background-position: 0 bottom;
  transform-origin: center bottom;
}

.waveTop {
  background-size: 50% 100px;
  background-image: url('./imgs/wave-top.png');
}
.waveMiddle {
  background-size: 50% 120px;
  background-image: url('./imgs/wave-mid.png');
}
.waveBottom {
  background-size: 50% 100px;
  background-image: url('./imgs/wave-bot.png');
}

.waveAnimation .waveTop {
  animation: move_wave 30s linear infinite;
  animation-delay: 1s;
}
.waveAnimation .waveMiddle {
  animation: move_wave 60s linear infinite;
  animation-delay: 2s;
}
.waveAnimation .waveBottom {
  animation: move_wave 120s linear infinite;
  animation-delay: 3s;
}
